import ImpactFilter from './ImpactFilter.jsx';

export const createCampaignChangesColumns = (data) => {
  const dates = [...new Set(data.map(item => item.date))].sort((a, b) => new Date(b) - new Date(a));

  const columns = [
    { headerName: "Country", field: "country_code", sortable: true, filter: true, width: 100, pinned: 'left', enableRowGroup: true },
    {
      headerName: "Campaign Name", field: "campaign_name", sortable: true, filter: 'agTextColumnFilter', width: 300, pinned: 'left', enableRowGroup: true,
      filterParams: {
        filterOptions: [
          {
            displayKey: 'multipleCampaigns',
            displayName: 'Multiple Campaigns',
            predicate: ([filterValue], cellValue) => {
              if (!filterValue) return true;
              const campaigns = filterValue.split(',')  // Split on commas
                                           .map(camp => camp.trim())   .filter(camp => camp !== '');
              return campaigns.some(campaign => cellValue.includes(campaign));
            },
            numberOfInputs: 1,
          }],}
    },
    { headerName: "Line", field: "line", sortable: true, filter: true, width: 130, pinned: 'left', enableRowGroup: true }, 
    { headerName: "DRI", field: "dri", sortable: true, filter: true, width: 100, pinned: 'left', enableRowGroup: true },    
  ];

  columns.push({
    headerName: 'L30',
    children: [
    { headerName: "Clicks",  field: "L30_clicks",  sortable: true,  filter: 'agNumberColumnFilter',  width: 100, 
      valueGetter: (params) => { if (!params.data) return 0; return dates.reduce((sum, date) => sum + (Number(params.data?.[`${date}_clicks`]) || 0), 0);},
      valueFormatter: params => params.value ? params.value.toLocaleString('en-US') : '',  cellStyle: { textAlign: 'right' },columnGroupShow: 'open',  aggFunc: 'sum'  },    
    { headerName: "Spend",  field: "L30_spend",  sortable: true,  filter: 'agNumberColumnFilter',  width: 120,  
      valueGetter: (params) => { if (!params.data) return 0;  return dates.reduce((sum, date) => sum + (Number(params.data[`${date}_spend`]) || 0), 0); },
      valueFormatter: params => params.value ? params.value.toLocaleString('en-US', { maximumFractionDigits: 0 }) : '',  cellStyle: { textAlign: 'right' },  aggFunc: 'sum' },
    { headerName: "Sales",  field: "L30_sales",  sortable: true,  filter: 'agNumberColumnFilter',  width: 120,  
      valueGetter: (params) => { if (!params.data) return 0; return dates.reduce((sum, date) => sum + (Number(params.data?.[`${date}_sales`]) || 0), 0); },
      valueFormatter: params => params.value ? params.value.toLocaleString('en-US', { maximumFractionDigits: 0 }) : '',  cellStyle: { textAlign: 'right' },  aggFunc: 'sum' },      
    { headerName: "Orders",  field: "L30_orders",  sortable: true,  filter: 'agNumberColumnFilter',  width: 120,  
      valueGetter: (params) => { if (!params.data) return 0; return dates.reduce((sum, date) => sum + (Number(params.data?.[`${date}_orders`]) || 0), 0); },
      valueFormatter: params => params.value ? params.value.toLocaleString('en-US', { maximumFractionDigits: 0 }) : '',  cellStyle: { textAlign: 'right' },columnGroupShow: 'open',  aggFunc: 'sum' },
    { headerName: "Units",  field: "L30_units",  sortable: true,  filter: 'agNumberColumnFilter',  width: 120,  
      valueGetter: (params) => { if (!params.data) return 0; return dates.reduce((sum, date) => sum + (Number(params.data?.[`${date}_units`]) || 0), 0); },
      valueFormatter: params => params.value ? params.value.toLocaleString('en-US', { maximumFractionDigits: 0 }) : '',  cellStyle: { textAlign: 'right' },columnGroupShow: 'open',  aggFunc: 'sum' },
    { headerName: "CVR",  field: "L30_cvr",  sortable: true,  filter: 'agNumberColumnFilter',  width: 100, 
      valueGetter: (params) => {
          const orders = dates.reduce((sum, date) => sum + (Number(params.data?.[`${date}_orders`]) || 0), 0);
          const clicks = dates.reduce((sum, date) => sum + (Number(params.data?.[`${date}_clicks`]) || 0), 0);
          return clicks !== 0 ? (orders / clicks) * 100 : 0; },
      valueFormatter: params => params.value ? `${params.value.toFixed(1)}%` : '0%',  columnGroupShow: 'open', cellStyle: { textAlign: 'right' } },
    { headerName: "ACoS",  field: "L30_acos",  sortable: true,  filter: 'agNumberColumnFilter',  width: 100, 
      valueGetter: (params) => {
          const spend = dates.reduce((sum, date) => sum + (Number(params.data?.[`${date}_spend`] || 0)), 0);  
          const sales = dates.reduce((sum, date) => sum + (Number(params.data?.[`${date}_sales`] || 0)), 0);  
          return sales !== 0 ? (spend / sales) * 100 : 0; },
      valueFormatter: params => params.value ? `${params.value.toFixed(1)}%` : '0%', cellStyle: { textAlign: 'right' } },
    ]
  });

  dates.forEach((date, index) => {
    const children = [
      { headerName: "Budget", field: `${date}_budget`, sortable: true, filter: 'agNumberColumnFilter', width: 120, valueFormatter: params => params.value ? params.value.toLocaleString('en-US', { maximumFractionDigits: 0 }) : '', cellStyle: { textAlign: 'right' }, columnGroupShow: 'open', aggFunc: 'sum' },
      { headerName: "Impressions", field: `${date}_impressions`, sortable: true, filter: 'agNumberColumnFilter', width: 120, valueFormatter: params => params.value ? params.value.toLocaleString('en-US') : '', cellStyle: { textAlign: 'right' }, columnGroupShow: 'open', aggFunc: 'sum' },
      { headerName: "Clicks", field: `${date}_clicks`, sortable: true, filter: 'agNumberColumnFilter', width: 100, valueFormatter: params => params.value ? params.value.toLocaleString('en-US') : '', cellStyle: { textAlign: 'right' }, columnGroupShow: 'open', aggFunc: 'sum' },
      { headerName: "Spend", field: `${date}_spend`, sortable: true, filter: 'agNumberColumnFilter', width: 120, valueFormatter: params => params.value ? params.value.toLocaleString('en-US', { maximumFractionDigits: 0 }) : '', cellStyle: { textAlign: 'right' }, aggFunc: 'sum' },
      { headerName: "Δ Spend", field: `${date}_delta_spend`, sortable: true, filter: 'agNumberColumnFilter', width: 120, 
        valueFormatter: params => {
          if (params.value === null || params.value === undefined) return '';
          return params.value > 0 ? `+${params.value.toLocaleString('en-US', { maximumFractionDigits: 0 })}` : params.value.toLocaleString('en-US', { maximumFractionDigits: 0 });
        }, 
        cellStyle: params => ({
          textAlign: 'right',
          color: params.value > 0 ? 'green' : params.value < 0 ? 'red' : 'inherit'
        }),
        columnGroupShow: 'open',
        aggFunc: 'sum'
      },
      { headerName: "Δ Spend %", field: `${date}_delta_spend_percent`, sortable: true, filter: 'agNumberColumnFilter', width: 120,
        valueFormatter: params => {
          if (params.value === null || params.value === undefined) return '';
          return `${(params.value * 100).toFixed(0)}%`;
        },
        cellStyle: params => ({
          textAlign: 'right',
          color: params.value > 0 ? 'green' : params.value < 0 ? 'red' : 'inherit'
        }),
        columnGroupShow: 'open'
      },
      { headerName: "Sales", field: `${date}_sales`, sortable: true, filter: 'agNumberColumnFilter', width: 120, valueFormatter: params => params.value ? params.value.toLocaleString('en-US', { maximumFractionDigits: 0 }) : '', cellStyle: { textAlign: 'right' }, aggFunc: 'sum' },
      { headerName: "Δ Sales", field: `${date}_delta_sales`, sortable: true, filter: 'agNumberColumnFilter', width: 120, 
        valueFormatter: params => {
          if (params.value === null || params.value === undefined) return '';
          return params.value > 0 ? `+${params.value.toLocaleString('en-US', { maximumFractionDigits: 0 })}` : params.value.toLocaleString('en-US', { maximumFractionDigits: 0 });
        }, 
        cellStyle: params => ({
          textAlign: 'right',
          color: params.value > 0 ? 'green' : params.value < 0 ? 'red' : 'inherit'
        }),
        columnGroupShow: 'open',
        aggFunc: 'sum'
      },
      { headerName: "Δ Sales %", field: `${date}_delta_sales_percent`, sortable: true, filter: 'agNumberColumnFilter', width: 120,
        valueFormatter: params => {
          if (params.value === null || params.value === undefined) return '';
          return `${(params.value * 100).toFixed(0)}%`;
        },
        cellStyle: params => ({
          textAlign: 'right',
          color: params.value > 0 ? 'green' : params.value < 0 ? 'red' : 'inherit'
        }),
        columnGroupShow: 'open'
      },
      { headerName: "Orders", field: `${date}_orders`, sortable: true, filter: 'agNumberColumnFilter', width: 100, valueFormatter: params => params.value ? params.value.toLocaleString('en-US') : '', cellStyle: { textAlign: 'right' }, columnGroupShow: 'open', aggFunc: 'sum' },
      { headerName: "Units", field: `${date}_units`, sortable: true, filter: 'agNumberColumnFilter', width: 100, valueFormatter: params => params.value ? params.value.toLocaleString('en-US') : '', cellStyle: { textAlign: 'right' }, columnGroupShow: 'open', aggFunc: 'sum' },
      { headerName: "CVR", field: `${date}_cvr`, sortable: true, filter: 'agNumberColumnFilter', width: 100, valueFormatter: params => params.value ? `${(params.value * 100).toFixed(1)}%` : '', cellStyle: { textAlign: 'right' }, columnGroupShow: 'open' },
      { headerName: "ACoS", field: `${date}_acos`, sortable: true, filter: 'agNumberColumnFilter', width: 100, valueFormatter: params => params.value ? `${Math.round(params.value * 100)}%` : '', cellStyle: { textAlign: 'right' } },
    ];
    if (index < dates.length - 1) {
      children.push({
        headerName: "Impact",
        field: `${date}_impact`,
        sortable: true,
        filter: ImpactFilter,
        filterParams: {
          suppressFilterButton: true,
        },
        width: 120,
        valueFormatter: params => {
          if (params.value === null || params.value === undefined) return '';
          return `${(params.value * 100).toFixed(1)}%`;
        },
        cellStyle: params => ({
          textAlign: 'right',
        }),
        cellClass: params => params.value >= 0.005 ? 'ag-cell-red' : params.value <= -0.005 ? 'ag-cell-green' : ''
      });
    }

    columns.push({
      headerName: date,
      children: children
    });
  });

  return columns;
};