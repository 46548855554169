import React, { useEffect, useState, useRef } from 'react';
import { Button, TextField, Tooltip, IconButton, Box, Tabs, Tab, Typography} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { AgGridReact } from 'ag-grid-react';
import { styled } from '@mui/material/styles';

const Repricing = () => {
    const [countryPricingData, setCountryPricingData] = useState('');
    const [linePricingData, setLinePricingData] = useState('');
    const [pricingData, setPricingData] = useState([]);
    const [pricingDataFeedback, setPricingDataFeedback] = useState({ message: '', style: {} });
    const gridRef = useRef(null);
    const [pricingFileFeedback, setPricingFileFeedback] = useState({ message: '', style: {} });

    const handlegetpricingData = async () => {
        setPricingDataFeedback({ message: 'Fetching pricing data...', style: { color: 'blue' } });
        try {
          const response = await fetch('/api/repricing/repricing_line', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({  
              country: countryPricingData,
              lines: linePricingData.split(',').map(line => line.trim())
            })
          });
    
          const result = await response.json();
          if (response.ok) {
            const dataWithIds = result.data.map((row, index) => ({
              ...row,
              id: index, 
            }));
            setPricingData(dataWithIds);
            setPricingDataFeedback({ message: 'Data fetched successfully', style: { color: 'blue' } });
          } else {
            setPricingDataFeedback({ message: `Error: ${result.error}`, style: { color: 'red' } });
          }
        } catch (error) {
          setPricingDataFeedback({ message: `Error: ${error.message}`, style: { color: 'red' } });
        }
      };

      const columns = [
        { headerName: "Country", field: "country", sortable: true, filter: true, width: 120 },
        { headerName: "Line", field: "line", sortable: true, filter: true, width: 150 },
        { headerName: "SKU", field: "sku", sortable: true, filter: true, width: 150 },
        { headerName: "Feed Product Type", field: "feed_product_type", sortable: true, filter: true, width: 200 },
        { headerName: "Live Price", field: "live_price", sortable: true, filter: true, width: 120 },
        { headerName: "New Price", field: "new_price", sortable: true, filter: true, width: 120, editable: true  },
        { headerName: "Min Price", field: "min_price", sortable: true, filter: true, width: 120, editable: true  },        
      ];
       
      <DataGrid
        rows={pricingData}  columns={columns}
        pageSize={5}  rowsPerPageOptions={[5]}
        getRowId={(row) => row.sku + row.asin}  
      />

      const handleSentPricingData = async () => {
        setPricingFileFeedback({ message: 'Preparing pricing update file...', style: { color: 'blue' } });
        try {
            const response = await fetch('/api/repricing/pricing_upload', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    country: countryPricingData,
                    lines: linePricingData.split(',').map(line => line.trim())
                })
            });

            const result = await response.json();

            if (response.ok) {
                const feedId = result.message.match(/Feed ID: ([A-Za-z0-9-]+)/)?.[1];
                const successMessage = feedId 
                    ? `Pricing update initiated successfully (Feed ID: ${feedId})`
                    : result.message;
                setPricingFileFeedback({ message: successMessage, style: { color: 'green' } });
            } else {
                setPricingFileFeedback({ message: `Error: ${result.error}`, style: { color: 'red' } });
            }
        } catch (error) {
            setPricingFileFeedback({ 
                message: `Error submitting pricing update: ${error.message}`, 
                style: { color: 'red' } 
            });
        }
    };

      const handleSubmitDataFromHQ = async () => {
        const rowData = [];
        
        gridRef.current.api.forEachNode((node) => {
          rowData.push(node.data);
        });
      
        try {
          const response = await fetch('/api/repricing/submit_data', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ 
              country: countryPricingData,
              data: rowData 
            }),
          });
      
          const result = await response.json();
          
          if (!response.ok) {
            throw new Error(result.error || 'Failed to submit data');
          }
      
          console.log('Data submitted successfully:', result);
          
        } catch (error) {
          console.error('Error submitting data:', error);
        } finally {
        }
      };  

    const handleTabChange = (event, newValue) => { setActiveTab(newValue);};
    const [activeTab, setActiveTab] = useState(0);

    const StyledTabs = styled(Tabs)(({ theme }) => ({
      marginBottom: theme.spacing(2),
      '& .MuiTab-root': {
        fontSize: '1.1rem',
        fontWeight: 'bold',
        minWidth: '150px',
      },
    }));
    
    const StyledTab = styled(Tab)(({ theme }) => ({
      marginRight: theme.spacing(2),
      '&.Mui-selected': {
      fontWeight: 'bold', color: 'inherit', 
      // backgroundColor: '#f0f0f0'
    }
    }));

    return (
      <Box m="20px">
     <Typography variant="h5" component="h1" gutterBottom style={{ fontWeight: 'bold', marginBottom: '0px' }}>
     Products Repricing
      </Typography>
      <StyledTabs 
        value={activeTab} 
        onChange={handleTabChange}
        indicatorColor="secondary"
        textColor="primary"
      >
        <StyledTab label="Line Wise" />
        <StyledTab label="Sku Wise" />
      </StyledTabs>
    
          {activeTab === 0 && (
      <div style={{ padding: '10px', marginTop: '-40px', height: '100vh', display: 'flex', flexDirection: 'column' }}>
      <Box mt={1}>
        <h3 style={{ marginBottom: '10px', marginLeft: '20px' }}></h3>
        <Box display="flex" alignItems="center" mb={2} ml={3}>
          <TextField
            label="Country"
            value={countryPricingData}
            onChange={(e) => setCountryPricingData(e.target.value)}
            style={{ marginRight: '10px' }}
          />
          <TextField
            label="Lines (comma-separated)"
            value={linePricingData}
            onChange={(e) => setLinePricingData(e.target.value)}
            style={{ marginRight: '10px', width: '500px' }}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handlegetpricingData}
            style={{ marginRight: '10px' }}
          >
            View Pricing Data
          </Button>
          <Button
            variant="contained"
            sx={{
              backgroundColor: '#ffd54f',
              color: '#000',
              marginRight: '10px',
              '&:hover': { backgroundColor: '#ffeb3b' },
              fontWeight: 'bold'
            }}
            onClick={handleSentPricingData}
          >
            Send Pricing Data
          </Button>
      </Box>

      {pricingDataFeedback.message && (
        <Box mt={-1.5} mb={0} ml={3}>
          <Typography style={pricingDataFeedback.style}>
            {pricingDataFeedback.message}
          </Typography>
        </Box>
      )}

      {pricingFileFeedback.message && (
        <Box mt={-2.3} mb={0} ml={27}>
          <Typography style={pricingFileFeedback.style}>
            {pricingFileFeedback.message}
          </Typography>
        </Box>
      )}
     </Box>
     <div className="ag-theme-alpine" style={{ flex: 1, marginLeft : '20px', width: '100%' }}>
     <AgGridReact
      ref={gridRef}
      columnDefs={columns}
      rowData={pricingData}
      defaultColDef={{
        sortable: true,
        filter: true,
        resizable: true,
        wrapHeaderText: true,
        autoHeaderHeight: true,
        cellRenderer: 'agCellRendererHtml',
      }}
      enableRangeSelection={true}
      multiSortKey="ctrl"
      pagination={true}
      paginationPageSize={100}
      paginationPageSizeSelector={[50, 100, 500, 1000]}
      suppressCellSelection={true}
      onGridReady={(params) => {
        params.api.sizeColumnsToFit();
      }}
    />
  </div>
  </div>
          )}
          {activeTab === 1 && (
                  <div style={{ padding: '10px', marginTop: '-40px', height: '100vh', display: 'flex', flexDirection: 'column' }}>
                  <Box mt={1}>
                    <h3 style={{ marginBottom: '10px', marginLeft: '20px' }}></h3>
                    <Box display="flex" alignItems="center" mb={2} ml={3}>
                      <TextField
                        label="Country"
                        value={countryPricingData}
                        onChange={(e) => setCountryPricingData(e.target.value)}
                        style={{ marginRight: '10px' }}
                      />
                      <TextField
                        label="Lines (comma-separated)"
                        value={linePricingData}
                        onChange={(e) => setLinePricingData(e.target.value)}
                        style={{ marginRight: '10px', width: '500px' }}
                      />
                      <Button
                        variant="contained"
                        color="primary"
                        // onClick={handlegetpricingData}
                        style={{ marginRight: '10px' }}
                      >
                        View Pricing Data
                      </Button>
                      <Button
                        variant="contained"
                        sx={{
                          backgroundColor: '#ffd54f',
                          color: '#000',
                          marginRight: '10px',
                          '&:hover': { backgroundColor: '#ffeb3b' },
                          fontWeight: 'bold'
                        }}
                        // onClick={handleSentPricingData}
                      >
                        Send Pricing Data
                      </Button>
                  </Box>
            
                  {pricingDataFeedback.message && (
                    <Box mt={-1.5} mb={0} ml={3}>
                      <Typography style={pricingDataFeedback.style}>
                        {pricingDataFeedback.message}
                      </Typography>
                    </Box>
                  )}
            
                  {pricingFileFeedback.message && (
                    <Box mt={-2.3} mb={0} ml={27}>
                      <Typography style={pricingFileFeedback.style}>
                        {pricingFileFeedback.message}
                      </Typography>
                    </Box>
                  )}
                 </Box>
                 <div className="ag-theme-alpine" style={{ flex: 1, marginLeft : '20px', width: '100%' }}>
                 <AgGridReact
                  ref={gridRef}
                  columnDefs={columns}
                  rowData={pricingData}
                  defaultColDef={{
                    sortable: true,
                    filter: true,
                    resizable: true,
                    wrapHeaderText: true,
                    autoHeaderHeight: true,
                    cellRenderer: 'agCellRendererHtml',
                  }}
                  enableRangeSelection={true}
                  multiSortKey="ctrl"
                  pagination={true}
                  paginationPageSize={100}
                  paginationPageSizeSelector={[50, 100, 500, 1000]}
                  suppressCellSelection={true}
                  onGridReady={(params) => {
                    params.api.sizeColumnsToFit();
                  }}
                />
              </div>
              </div>
          )}    
  </Box>
)};


export default Repricing;