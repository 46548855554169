import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import { Box, Typography, useTheme, Button, Tabs, Tab, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { tokens } from "../../theme";
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import Header from "../../components/Header";

const ProductLines = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [countries, setCountries] = useState([]);
  const [lines, setLines] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState('');
  const [selectedLine, setSelectedLine] = useState('');
  const [tabValue, setTabValue] = useState(0);
  const [rowData, setRowData] = useState([]);
  const gridRef = useRef(null);

  useEffect(() => {
    fetchCountriesAndLines();
  }, []);

  useEffect(() => {
    if (selectedCountry && selectedLine) {
      fetchLineData();
    }
  }, [selectedCountry, selectedLine]);

  const fetchCountriesAndLines = async () => {
    try {
      const response = await fetch('/api/catalog/lines/all');
      const data = await response.json();
      const uniqueCountries = [...new Set(data.map(item => item.country))];
      setCountries(uniqueCountries);
      setLines([...new Set(data.map(item => item.line))]);
    } catch (error) {
      console.error('Error fetching countries and lines:', error);
    }
  };

  const fetchLineData = async () => {
    try {
      const response = await fetch(`/api/line/get_line_daily_data?country=${selectedCountry}&line=${selectedLine}`);
      const data = await response.json();
      setRowData(data);
    } catch (error) {
      console.error('Error fetching line data:', error);
    }
  };

  const handleCountryChange = (event) => {
    setSelectedCountry(event.target.value);
  };

  const handleLineChange = (event) => {
    setSelectedLine(event.target.value);
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const columns = [
    // Add your column definitions here, similar to line_daily.jsx
    // Make sure to adjust them according to the data you're receiving
  ];

  const [columnAdsKeyword, setColumnAdsKeyword] = useState([]);
  const [rowDataKeyword, setRowDataKeyword] = useState([]);

  const handleAdsKeyword = useCallback(async () => {
    try {
      console.log('Initiating Ads Keyword Data');
      const response = await fetch('/api/ads/get_adskeyword_data');
      if (!response.ok) {
        const err = await response.json();
        throw new Error(`HTTP error! status: ${response.status}, message: ${err.error || 'Unknown error'}`);
      }
      const data = await response.json();
      // console.log('Received data from API:', data);
  
      if (Array.isArray(data) && data.length > 0) {
        const dates = extractDates(data);
        const columns = generateAdsColumnsDef(dates);
        setColumnAdsKeyword(columns);
        setRowDataKeyword(data);
      } else {
        console.error("Data is empty or not in expected format", data);
      }
    } catch (err) {
      console.error('Error fetching data:', err.message);
    }
  }, []);

  useEffect(() => {
    if (tabValue === 4) {
      handleAdsKeyword();
    }
  }, [tabValue, handleAdsKeyword]);

  const extractDates = (data) => {
    if (!Array.isArray(data) || data.length === 0) return [];  
    const row = data[0];  
    const allKeys = typeof row === 'object' && row !== null ? Object.keys(row) : [];  
    const dateKeys = allKeys.filter((key) => key.match(/^\d{4}-\d{2}-\d{2}/));
    // Get unique date values by extracting just the date part (ignoring 'clicks' and 'orders')
    const dates = [...new Set(dateKeys.map(key => key.split(' ')[0]))];  
    return dates;
  };

  // Generate column definitions dynamically from the dates
  const generateAdsColumnsDef = (dates) => {
    const sortedDates = dates.sort((a, b) => new Date(b) - new Date(a));
    const staticColumns = [
      {
        headerName: 'Search Term',
        children: [
          { headerName: "Country", field: "country", sortable: true, filter: true, width: 120 , pinned : 'left'},
          { headerName: "Line", field: "line", sortable: true, filter: true, width: 110 , pinned : 'left' },
          { headerName: "Search Term", field: "search_term", sortable: true, filter: true, width: 220 , pinned : 'left' }
        ]
      }
    ];

    const dateColumns = sortedDates.map(date => ({
      headerName: date,
      children: [
        { headerName: "Clicks", field: `${date} clicks`, sortable: true, filter: 'agNumberColumnFilter',cellStyle: { textAlign: 'right' }, width: 110 },
        { headerName: "Orders", field: `${date} orders`, sortable: true, filter: 'agNumberColumnFilter',cellStyle: { textAlign: 'right' }, width: 110 },
        { headerName: "ACoS %", field: `${date} acos`, sortable: true, filter: 'agNumberColumnFilter', width: 110, cellStyle: { textAlign: 'right' },
        valueFormatter: params => params.value ? (params.value * 100).toFixed(1) + '%' : '' } 
      ]
    }));

    return [...staticColumns, ...dateColumns];
  };

  const sideBar = useMemo(() => ({
    toolPanels: [
      {
        id: 'columnAdsKeyword',
        labelDefault: 'Columns',
        labelKey: 'columnAdsKeyword',
        iconKey: 'columnAdsKeyword',
        toolPanel: 'agColumnsToolPanel',
      },
      {
        id: 'filters',
        labelDefault: 'Filters',
        labelKey: 'filters',
        iconKey: 'filter',
        toolPanel: 'agFiltersToolPanel',
      },
    ],
    defaultToolPanel: null,
  }), []);

  const handleExportCSV = useCallback(() => {
    gridRef.current.api.exportDataAsCsv();
  }, []);
  const handleClearFilters = useCallback(() => {
    gridRef.current.api.setFilterModel(null);
    const quickFilterInput = document.querySelector('input[placeholder="Quick Filter... (Press Enter to apply)"]');
    if (quickFilterInput) {
      quickFilterInput.value = '';
    }
  }, []);

  // Function to adjust grid height dynamically based on available viewport height
  const [availableHeight, setAvailableHeight] = useState(0);
  const tableContainerRef = useRef();
  const calculateHeight = () => {
    if (tableContainerRef.current) {
      const offsetHeight = tableContainerRef.current.offsetTop + 50; // 50 to account for padding, marging, etc.
      setAvailableHeight(window.innerHeight - offsetHeight);
    }
  };
  useEffect(() => {
    calculateHeight(); // Initial calculation on component mount
    window.addEventListener('resize', calculateHeight); // Recalculate on window resize
    return () => window.removeEventListener('resize', calculateHeight); // Cleanup on unmount
  }, []);


  return (
<Box m="20px">
  <div style={{ display: 'inline-flex', alignItems: 'center' }}>
    <Header title="Product Lines" />
    
    <div style={{ marginLeft: '50px', display: 'inline-flex', alignItems: 'center' }}>
      <FormControl style={{ minWidth: 120 }}>
        <InputLabel>Country</InputLabel>
        <Select value={selectedCountry} onChange={handleCountryChange}>
          {countries.map(country => (
            <MenuItem key={country} value={country}>
              {country}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      
      <FormControl style={{ minWidth: 120, marginLeft: '10px' }}>
        <InputLabel>Line</InputLabel>
        <Select value={selectedLine} onChange={handleLineChange}>
          {lines.map(line => (
            <MenuItem key={line} value={line}>
              {line}
            </MenuItem>
          ))}
        </Select>
        </FormControl>
    </div> </div>

      <Tabs value={tabValue} onChange={handleTabChange}>
        <Tab label="Home" />
        <Tab label="Competitors" />
        <Tab label="MKL" />
        <Tab label="Content" />
        <Tab label="Keyword" />
      </Tabs>

      {tabValue === 0 && (
        <Box mt={2}>
          <div className="ag-theme-alpine" style={{ height: 600, width: '100%' }}>
            <AgGridReact
              ref={gridRef}
              rowData={rowData}
              columnAdsKeyword={columns}
              defaultColDef={{
                sortable: true,
                filter: true,
                resizable: true,
              }}
              pagination={true}
              paginationPageSize={50}
            />
          </div>
        </Box>
      )}

{tabValue === 4 && (
  <Box mt={-3.8} style={{ width: '100%' }}>
    <Box style={{ display: 'flex', justifyContent: 'flex-end', width: '100%', marginBottom: '10px' }}>
      <Button variant="contained" color="primary" onClick={handleExportCSV} style={{ marginRight: '10px' }} >
        Export CSV </Button>

      <Button variant="contained" color="secondary" onClick={handleClearFilters}>
        Clear Filters </Button>
    </Box>

    <Box ref={tableContainerRef} style={{ flexGrow: 1, overflow: 'hidden' }}>
        <div className="ag-theme-alpine"
        style={{ height: availableHeight ? `${availableHeight}px` : '800px',  width: '100%',overflowY: 'auto', }}>
        <AgGridReact
        ref={gridRef}
        columnDefs={columnAdsKeyword}
        rowData={rowDataKeyword}
        defaultColDef={{
          sortable: true,
          filter: true,
          resizable: true,
          wrapHeaderText: true,
          autoHeaderHeight: true,
          cellRenderer: 'agCellRendererHtml',
        }}
        pagination={true}
        enableRangeSelection={true}
        multiSortKey="ctrl"
        sideBar={sideBar}
        paginationPageSize={50}
        paginationPageSizeSelector={[50, 100, 500, 1000]}
        grandTotalRow="bottom"
        groupDefaultExpanded={0}
        paginationNumberFormatter={(params) => {
          return '[' + params.value.toLocaleString() + ']';
        }}
        rowHeight={25}
        cacheQuickFilter={true}
      />
     </div>
     </Box>
        </Box>
      )}
      {/* Add other tab content here */}
    </Box>
  );
};



export default ProductLines;
