import React, { useState, useEffect } from 'react';
import { Box, Typography, useTheme, Modal, TextField, Button } from "@mui/material";
import Tree from 'rc-tree';
import 'rc-tree/assets/index.css';
import { tokens } from "../../theme";
import ImagePreview from '../dam/ImagePreview';
import FolderIcon from '@mui/icons-material/Folder';
import ImageIcon from '@mui/icons-material/Image';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import DeleteIcon from '@mui/icons-material/Delete';
import DriveFileMoveIcon from '@mui/icons-material/DriveFileMove';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import EditIcon from '@mui/icons-material/Edit';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';

const DAMFolder = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [treeData, setTreeData] = useState([]);
  const [error, setError] = useState(null);
  const [previewInfo, setPreviewInfo] = useState({ show: false, src: '', x: 0, y: 0 });
  const [contextMenu, setContextMenu] = useState(null);
  const [selectedNode, setSelectedNode] = useState(null);
  const [bulkFolderModal, setBulkFolderModal] = useState(false);
  const [bulkFolderNames, setBulkFolderNames] = useState('');
  const [currentFolderPath, setCurrentFolderPath] = useState(null);
  const [renameModal, setRenameModal] = useState(false);
  const [newFolderName, setNewFolderName] = useState('');
  const [duplicateModal, setDuplicateModal] = useState(false);
  const [duplicateFolderNames, setDuplicateFolderNames] = useState('');
  const [selectedFiles, setSelectedFiles] = useState(new Set());
  const [copiedFiles, setCopiedFiles] = useState([]);
  const [selectionMode, setSelectionMode] = useState(false);

  useEffect(() => {
    fetch('/api/dam/get_folder_structure')
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then(data => {
        console.log("Received tree data:", data);
        if (Array.isArray(data) && data.length > 0) {
          setTreeData(data);
        } else {
          console.error("Invalid tree data structure received:", data);
          setError("Invalid data structure received from server");
        }
      })
      .catch(error => {
        console.error('Error fetching folder structure:', error);
        setError(error.message);
      });
  }, []);

  const handleSelect = (selectedKeys, info) => {
    if (selectionMode) {
      handleFileSelection(info.node);
    } else if (info.node.isLeaf) {
      window.open(info.node.url, '_blank');
    }
  };

  const handleMouseEnter = (info) => {
    if (info.node.isLeaf) {
      setPreviewInfo({
        show: true,
        src: info.node.url,
        x: info.event.clientX + 10,
        y: info.event.clientY + 10,
      });
    }
  };

  const handleMouseLeave = () => {
    setPreviewInfo({ show: false, src: '', x: 0, y: 0 });
  };

  const handleMouseMove = (e) => {
    if (previewInfo.show) {
      setPreviewInfo(prev => ({
        ...prev,
        x: e.clientX + 10,
        y: e.clientY + 10,
      }));
    }
  };

  // Custom icon renderer - updated for black icons
  const iconRenderer = (props) => {
    if (!props) return null;
    
    const { isLeaf, expanded } = props;
    // Get node from props.data for rc-tree
    const node = props.data;
    
    if (selectionMode && isLeaf && node?.key) {
      return selectedFiles.has(node.key) ? 
        <CheckBoxIcon sx={{ fontSize: 20, color: '#1976d2', mr: 1 }} /> :
        <CheckBoxOutlineBlankIcon sx={{ fontSize: 20, color: '#000', mr: 1 }} />;
    }
    
    if (isLeaf) {
      const isCopied = copiedFiles.some(file => file.key === node?.key);
      return <ImageIcon sx={{ 
        fontSize: 20, 
        color: isCopied ? '#2e7d32' : // green color for copied files
               (node?.key && selectedFiles.has(node.key)) ? '#1976d2' : '#000', 
        mr: 1 
      }} />;
    }
    
    return expanded ? 
      <FolderOpenIcon sx={{ fontSize: 20, color: '#000', mr: 1 }} /> : 
      <FolderIcon sx={{ fontSize: 20, color: '#000', mr: 1 }} />;
  };

  const handleContextMenu = (e, node) => {
    e.preventDefault();
    console.log("Right-clicked node:", node);
    setContextMenu({
      x: e.clientX,
      y: e.clientY,
    });
    setSelectedNode(node);
    setCurrentFolderPath(node.key);
  };

  const handleCloseContextMenu = () => {
    setContextMenu(null);
  };

  const handleBulkFolderCreate = async () => {
    console.log("Current selectedNode:", selectedNode);
    console.log("Current folderPath:", currentFolderPath);
    console.log("Current bulkFolderNames:", bulkFolderNames);
    
    if (!currentFolderPath || !bulkFolderNames.trim()) {
      console.log("Validation failed:", {
        hasSelectedNode: !!currentFolderPath,
        hasFolderNames: !!bulkFolderNames.trim()
      });
      return;
    }

    try {
      const response = await fetch('/api/dam/create_bulk_folders', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        body: JSON.stringify({
          parentPath: currentFolderPath,
          folderNames: bulkFolderNames.split('\n').filter(name => name.trim()),
        }),
      });

      console.log("Response status:", response.status);
      
      const responseData = await response.json();
      console.log("Response data:", responseData);

      if (!response.ok) {
        throw new Error(responseData.error || `HTTP error! status: ${response.status}`);
      }

      // Refresh the tree data
      console.log("Refreshing tree data...");
      const refreshResponse = await fetch('/api/dam/get_folder_structure');
      if (!refreshResponse.ok) {
        throw new Error(`Failed to refresh tree data: ${refreshResponse.status}`);
      }
      const newData = await refreshResponse.json();
      setTreeData(newData);

      // Close modal and clear input
      setBulkFolderModal(false);
      setBulkFolderNames('');
      handleCloseContextMenu();
      setCurrentFolderPath(null);
      
      // Show success message
      setError(null);
    } catch (error) {
      console.error('Error in handleBulkFolderCreate:', error);
      setError(`Failed to create folders: ${error.message}`);
    }
  };

  const handleRename = async () => {
    if (selectedNode?.isLeaf) {
      await handleFileRename();
    } else {
      await handleFolderRename();
    }
  };

  const handleFolderRename = async () => {
    if (!currentFolderPath || !newFolderName.trim()) return;

    try {
      const response = await fetch('/api/dam/rename_folder', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        body: JSON.stringify({
          folderPath: currentFolderPath,
          newName: newFolderName.trim(),
        }),
      });

      const responseData = await response.json();
      if (!response.ok) throw new Error(responseData.error);

      await refreshTreeData();
      setRenameModal(false);
      setNewFolderName('');
      handleCloseContextMenu();
    } catch (error) {
      console.error('Error renaming folder:', error);
      setError(`Failed to rename folder: ${error.message}`);
    }
  };

  const handleFileRename = async () => {
    if (!currentFolderPath || !newFolderName.trim()) return;

    try {
      const response = await fetch('/api/dam/rename_file', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        body: JSON.stringify({
          filePath: currentFolderPath,
          newName: newFolderName.trim(),
        }),
      });

      const responseData = await response.json();
      if (!response.ok) throw new Error(responseData.error);

      await refreshTreeData();
      setRenameModal(false);
      setNewFolderName('');
      handleCloseContextMenu();
    } catch (error) {
      console.error('Error renaming file:', error);
      setError(`Failed to rename file: ${error.message}`);
    }
  };

  const handleDelete = async () => {
    if (!currentFolderPath) return;

    if (window.confirm('Are you sure you want to delete this folder and all its contents?')) {
      try {
        const response = await fetch('/api/dam/delete_folder', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
          },
          body: JSON.stringify({
            folderPath: currentFolderPath,
          }),
        });

        const responseData = await response.json();
        if (!response.ok) throw new Error(responseData.error);

        await refreshTreeData();
        handleCloseContextMenu();
      } catch (error) {
        console.error('Error deleting folder:', error);
        setError(`Failed to delete folder: ${error.message}`);
      }
    }
  };

  const handleDuplicate = async () => {
    if (!currentFolderPath || !duplicateFolderNames.trim()) return;

    try {
      const response = await fetch('/api/dam/duplicate_folder', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        body: JSON.stringify({
          sourcePath: currentFolderPath,
          targetNames: duplicateFolderNames.split('\n').filter(name => name.trim()),
        }),
      });

      const responseData = await response.json();
      if (!response.ok) throw new Error(responseData.error);

      await refreshTreeData();
      setDuplicateModal(false);
      setDuplicateFolderNames('');
      handleCloseContextMenu();
    } catch (error) {
      console.error('Error duplicating folder:', error);
      setError(`Failed to duplicate folder: ${error.message}`);
    }
  };

  const refreshTreeData = async () => {
    const refreshResponse = await fetch('/api/dam/get_folder_structure');
    if (!refreshResponse.ok) {
      throw new Error(`Failed to refresh tree data: ${refreshResponse.status}`);
    }
    const newData = await refreshResponse.json();
    setTreeData(newData);
  };

  // Add new handler for selection mode toggle
  const toggleSelectionMode = (e) => {
    e.preventDefault();
    setSelectionMode(!selectionMode);
    if (!selectionMode) {
      setSelectedFiles(new Set());
    }
  };

  // Add file selection handler
  const handleFileSelection = (node) => {
    if (node.isLeaf) {
      const newSelection = new Set(selectedFiles);
      if (newSelection.has(node.key)) {
        newSelection.delete(node.key);
      } else {
        newSelection.add(node.key);
      }
      setSelectedFiles(newSelection);
    }
  };

  // Add copy files handler
  const handleCopyFiles = () => {
    const filesList = Array.from(selectedFiles).map(key => {
      const node = findNodeByKey(treeData, key);
      return {
        key: node.key,
        url: node.url,
        title: node.title
      };
    });
    setCopiedFiles(filesList);
    setSelectionMode(false);
    setSelectedFiles(new Set());
  };

  // Helper function to find node by key
  const findNodeByKey = (nodes, key) => {
    for (const node of nodes) {
      if (node.key === key) return node;
      if (node.children) {
        const found = findNodeByKey(node.children, key);
        if (found) return found;
      }
    }
    return null;
  };

  // Add paste files handler
  const handlePasteFiles = async () => {
    if (!currentFolderPath || copiedFiles.length === 0) return;

    try {
      const response = await fetch('/api/dam/paste_files', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        body: JSON.stringify({
          targetPath: currentFolderPath,
          files: copiedFiles
        }),
      });

      const responseData = await response.json();
      if (!response.ok) throw new Error(responseData.error);

      await refreshTreeData();
      handleCloseContextMenu();
    } catch (error) {
      console.error('Error pasting files:', error);
      setError(`Failed to paste files: ${error.message}`);
    }
  };

  const handleFileDelete = async () => {
    if (!currentFolderPath) return;

    if (window.confirm('Are you sure you want to delete this file?')) {
      try {
        const response = await fetch('/api/dam/delete_file', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
          },
          body: JSON.stringify({
            filePath: currentFolderPath,
          }),
        });

        const responseData = await response.json();
        if (!response.ok) throw new Error(responseData.error);

        await refreshTreeData();
        handleCloseContextMenu();
      } catch (error) {
        console.error('Error deleting file:', error);
        setError(`Failed to delete file: ${error.message}`);
      }
    }
  };

  useEffect(() => {
    const treeNodes = document.querySelectorAll('.rc-tree-treenode');
    
    treeNodes.forEach(node => {
      const nodeKey = node.getAttribute('data-key');
      if (nodeKey) {
        node.setAttribute('data-selected', selectedFiles.has(nodeKey).toString());
        node.setAttribute('data-copied', 
          copiedFiles.some(file => file.key === nodeKey).toString()
        );
      }
    });
  }, [selectedFiles, copiedFiles]);

  return (
    <div style={{ padding: '20px', height: '100vh', backgroundColor: 'white' }} onMouseMove={handleMouseMove}>
      <Box mb={2} display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h4" sx={{ color: '#000' }}>DAM Folder Browser</Typography>
        <Box>
          {selectionMode && selectedFiles.size > 0 && (
            <Button
              onClick={handleCopyFiles}
              variant="contained"
              color="primary"
              sx={{ mr: 2 }}
            >
              Copy Selected ({selectedFiles.size})
            </Button>
          )}
          {copiedFiles.length > 0 && !selectionMode && (
            <Button
              onClick={() => setCopiedFiles([])}
              variant="outlined"
              color="error"
              sx={{ mr: 2 }}
            >
              Clear Copied ({copiedFiles.length})
            </Button>
          )}
          <Button
            onClick={toggleSelectionMode}
            variant="outlined"
            color={selectionMode ? "secondary" : "primary"}
          >
            {selectionMode ? "Cancel Selection" : "Select Files"}
          </Button>
        </Box>
      </Box>
      
      {error ? (
        <Typography color="error" variant="h6">
          Error: {error}
        </Typography>
      ) : (
        <Box
          sx={{
            '.rc-tree': {
              background: 'white',
              color: '#000',
              padding: '20px',
              '& .rc-tree-list': {
                backgroundColor: '#f5f5f5',
              }
            },
            '.rc-tree-treenode': {
              padding: '1px 0',
              '&:hover': {
                backgroundColor: '#e6f4ff',
              },
              '&[data-selected="true"]': {
                backgroundColor: '#e3f2fd',
                '& .rc-tree-node-content-wrapper': {
                  color: '#1976d2',
                  fontWeight: 500,
                }
              },
              '&[data-copied="true"]': {
                '& .rc-tree-node-content-wrapper': {
                  color: '#2e7d32',
                  fontStyle: 'italic',
                  '&::after': {
                    content: '" (copied)"',
                    fontSize: '0.8em',
                    marginLeft: '4px',
                    color: '#2e7d32',
                  }
                }
              }
            },
            '.rc-tree-node-content-wrapper': {
              padding: '0 4px',
              display: 'flex',
              alignItems: 'center',
              '&:hover': {
                backgroundColor: 'transparent',
              },
            },
            '.rc-tree-node-selected': {
              backgroundColor: '#bae7ff',
              '&:hover': {
                backgroundColor: '#bae7ff',
              },
            },
            '.rc-tree-title': {
              color: '#000',
              fontSize: '14px',
              lineHeight: '24px',
              fontFamily: '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial',
              paddingLeft: '8px',
            },
            '.rc-tree-switcher': {
              display: 'none',
            },
            '.rc-tree-indent-unit': {
              width: '24px',
            },
            '.rc-tree-node-content-wrapper.dragging': {
              backgroundColor: '#e6f4ff',
              opacity: 0.8,
            },
            '.rc-tree-list-holder-inner': {
              '& > div': {
                borderBottom: '1px solid #f0f0f0',
              }
            }
          }}
          onClick={handleCloseContextMenu}
        >
          <Tree
            treeData={treeData}
            onSelect={handleSelect}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            defaultExpandAll={false}
            showIcon={true}
            icon={iconRenderer}
            switcherIcon={null}
            onRightClick={(info) => handleContextMenu(info.event, info.node)}
            showLine={true}
            checkable={false}
            selectable={true}
            motion={null}
            style={{ background: 'white' }}
          />
        </Box>
      )}
      
      {/* Context Menu - updated styling */}
      {contextMenu && (
        <div
          style={{
            position: 'fixed',
            top: contextMenu.y,
            left: contextMenu.x,
            backgroundColor: '#000',
            border: `1px solid ${colors.grey[300]}`,
            borderRadius: '4px',
            padding: '8px',
            zIndex: 1000,
            boxShadow: '0px 2px 4px rgba(0,0,0,0.1)',
          }}
        >
          <Button
            onClick={() => {
              setRenameModal(true);
              handleCloseContextMenu();
            }}
            startIcon={<EditIcon />}
            sx={{ 
              color: '#fff', 
              display: 'flex', 
              textAlign: 'left',
              width: '100%',
              justifyContent: 'flex-start',
              '&:hover': {
                backgroundColor: colors.grey[800],
              }
            }}
          >
            Rename
          </Button>
          <Button
            onClick={selectedNode?.isLeaf ? handleFileDelete : handleDelete}
            startIcon={<DeleteIcon />}
            sx={{ 
              color: '#fff', 
              display: 'flex', 
              textAlign: 'left',
              width: '100%',
              justifyContent: 'flex-start',
              '&:hover': {
                backgroundColor: colors.grey[800],
              }
            }}
          >
            Delete
          </Button>
          {!selectedNode?.isLeaf && (
            <>
              <Button
                onClick={() => {
                  setBulkFolderModal(true);
                  handleCloseContextMenu();
                }}
                startIcon={<DriveFileMoveIcon />}
                sx={{ 
                  color: '#fff', 
                  display: 'flex', 
                  textAlign: 'left',
                  width: '100%',
                  justifyContent: 'flex-start',
                  '&:hover': {
                    backgroundColor: colors.grey[800],
                  }
                }}
              >
                Add Bulk Folders
              </Button>
              <Button
                onClick={() => {
                  setDuplicateModal(true);
                  handleCloseContextMenu();
                }}
                startIcon={<ContentCopyIcon />}
                sx={{ 
                  color: '#fff', 
                  display: 'flex', 
                  textAlign: 'left',
                  width: '100%',
                  justifyContent: 'flex-start',
                  '&:hover': {
                    backgroundColor: colors.grey[800],
                  }
                }}
              >
                Duplicate to Multiple Folders
              </Button>
            </>
          )}
          {copiedFiles.length > 0 && !selectedNode?.isLeaf && (
            <Button
              onClick={handlePasteFiles}
              startIcon={<ContentPasteIcon />}
              sx={{ 
                color: '#fff', 
                display: 'flex', 
                textAlign: 'left',
                width: '100%',
                justifyContent: 'flex-start',
                '&:hover': {
                  backgroundColor: colors.grey[800],
                }
              }}
            >
              Paste Files ({copiedFiles.length})
            </Button>
          )}
        </div>
      )}

      {/* Update Modal styling */}
      <Modal
        open={bulkFolderModal}
        onClose={() => setBulkFolderModal(false)}
        aria-labelledby="bulk-folder-modal"
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'white',
          border: `1px solid ${colors.grey[300]}`,
          borderRadius: '8px',
          boxShadow: 24,
          p: 4,
        }}>
          <Typography variant="h6" component="h2" sx={{ mb: 2, color: '#000', fontWeight: 'bold' }}>
            Create Bulk Folders
          </Typography>
          <TextField
            fullWidth
            multiline
            rows={10}
            value={bulkFolderNames}
            onChange={(e) => setBulkFolderNames(e.target.value)}
            placeholder="Enter folder names (one per line)"
            sx={{
              mb: 2,
              '& .MuiInputBase-input': {
                color: '#000',
              },
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: colors.grey[300],
                },
                '&:hover fieldset': {
                  borderColor: colors.grey[400],
                },
              },
            }}
          />
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
            <Button
              onClick={() => setBulkFolderModal(false)}
              sx={{ color: '#000' }}
            >
              Cancel
            </Button>
            <Button
              onClick={handleBulkFolderCreate}
              variant="contained"
              color="primary"
            >
              Create Folders
            </Button>
          </Box>
        </Box>
      </Modal>

      {/* Add Rename Modal */}
      <Modal
        open={renameModal}
        onClose={() => setRenameModal(false)}
        aria-labelledby="rename-modal"
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'white',
          border: `1px solid ${colors.grey[300]}`,
          borderRadius: '8px',
          boxShadow: 24,
          p: 4,
        }}>
          <Typography variant="h6" component="h2" sx={{ mb: 2, color: '#000', fontWeight: 'bold' }}>
            Rename Folder
          </Typography>
          <TextField
            fullWidth
            value={newFolderName}
            onChange={(e) => setNewFolderName(e.target.value)}
            placeholder="Enter new folder name"
            sx={{
              mb: 2,
              '& .MuiInputBase-input': {
                color: '#000',
              },
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: colors.grey[300],
                },
                '&:hover fieldset': {
                  borderColor: colors.grey[400],
                },
              },
            }}
          />
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
            <Button onClick={() => setRenameModal(false)} sx={{ color: '#000' }}>
              Cancel
            </Button>
            <Button onClick={handleRename} variant="contained" color="primary">
              Rename
            </Button>
          </Box>
        </Box>
      </Modal>

      {/* Add Duplicate Modal */}
      <Modal
        open={duplicateModal}
        onClose={() => setDuplicateModal(false)}
        aria-labelledby="duplicate-modal"
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'white',
          border: `1px solid ${colors.grey[300]}`,
          borderRadius: '8px',
          boxShadow: 24,
          p: 4,
        }}>
          <Typography variant="h6" component="h2" sx={{ mb: 2, color: '#000', fontWeight: 'bold' }}>
            Duplicate to Multiple Folders
          </Typography>
          <TextField
            fullWidth
            multiline
            rows={10}
            value={duplicateFolderNames}
            onChange={(e) => setDuplicateFolderNames(e.target.value)}
            placeholder="Enter folder names (one per line)"
            sx={{
              mb: 2,
              '& .MuiInputBase-input': {
                color: '#000',
              },
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: colors.grey[300],
                },
                '&:hover fieldset': {
                  borderColor: colors.grey[400],
                },
              },
            }}
          />
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
            <Button onClick={() => setDuplicateModal(false)} sx={{ color: '#000' }}>
              Cancel
            </Button>
            <Button onClick={handleDuplicate} variant="contained" color="primary">
              Duplicate
            </Button>
          </Box>
        </Box>
      </Modal>

      {previewInfo.show && (
        <ImagePreview
          src={previewInfo.src}
          style={{
            left: `${previewInfo.x}px`,
            top: `${previewInfo.y}px`,
          }}
        />
      )}
    </div>
  );
};

export default DAMFolder; 