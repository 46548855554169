import React, { useState, useCallback, useEffect } from 'react';
import { Box, Button, Typography, Snackbar, Paper, Grid } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import RefreshIcon from '@mui/icons-material/Refresh';

const CacheClearingPanel = () => {
  const [messages, setMessages] = useState({
    cosp: '',
    linecache: '',
    linedetailcache: '',
    campaigns: '',
    adskeywords : '',
  });

  const [lastClearedTime, setLastClearedTime] = useState(null);
  const [lastCospClearedTime, setLastCospClearedTime] = useState(null);
  const [lastLineDetailClearedTime, setLastLineDetailClearedTime] = useState(null);  
  const [lastCampaignsClearedTime , setLastCampaignsClearedTime] = useState(null);
  const [lastAdsKeywordClearedTime, setLastAdsKeywordClearedTime] = useState(null)

  const handleClearCOSPCache = useCallback(() => {
    fetch('/api/cosp/clear_cosp_cache', { method: 'POST' })
      .then(response => {
        if (!response.ok) {
          throw new Error('Failed to clear COSP cache');
        }
        return response.json();
      })
      .then(data => {
        console.log(data.message);
        setMessages(prev => ({ ...prev, cosp: data.message }));
        fetchLastCospClearedTime(); 
      })
      .catch(error => {
        console.log(error.message);
        setMessages(prev => ({ ...prev, cosp: `Error clearing COSP cache: ${error.message}` }));
        setLastCospClearedTime(`Error clearing cache: ${error.message}`);
      });
  }, []);

  const fetchLastCospClearedTime = useCallback(() => {
    fetch('/api/cosp/get_last_cosp_cache_clear_time', { method: 'GET' })
      .then(response => {
        if (!response.ok) {  throw new Error('Failed to fetch last cache clear time');  }
        return response.json(); })
      .then(data => {  setLastCospClearedTime(data.last_cleared_time);  })
      .catch(error => {  console.error('Fetch error:', error);
        setLastCospClearedTime(`Error fetching last cleared time: ${error.message}`);  });
  }, []);

  const handleClearLineCache = useCallback(() => {
    fetch('/api/line/clear_line_cache', { method: 'POST' })
      .then(response => {
        if (!response.ok) {
          throw new Error('Failed to clear Line Daily cache');
        }
        return response.json();
      })
      .then(data => {
        console.log(data.message);
        setMessages(prev => ({ ...prev, lineDaily: data.message }));
        fetchLastClearedTime(); 
      })
      .catch(error => {
        console.log(error.message);
        setMessages(prev => ({ ...prev, lineDaily: `Error clearing Line Daily cache: ${error.message}` }));
        setLastClearedTime(`Error clearing cache: ${error.message}`);
      });
  }, []);

  const fetchLastClearedTime = useCallback(() => {
    fetch('/api/line/get_last_cache_clear_time', { method: 'GET' })
      .then(response => {
        if (!response.ok) {  throw new Error('Failed to fetch last cache clear time');  }
        return response.json(); })
      .then(data => {  setLastClearedTime(data.last_cleared_time);  })
      .catch(error => {  console.error('Fetch error:', error);
        setLastClearedTime(`Error fetching last cleared time: ${error.message}`);  });
  }, []);

  const handleClearLineDetailCache = useCallback(() => {
    fetch('/api/line-details/clear_line_detail_cache', { method: 'POST' })
    .then(response => {
      console.log('Response status:', response.status);
      if (!response.ok) {
        return response.text().then(text => { throw new Error(text) });
      }
      return response.json();
    })
    .then(data => {
      setMessages(prev => ({ ...prev, linedetailcache: data.message }));
      fetchLastLineDetailClearedTime(); 
    })
    .catch(error => {
      setMessages(prev => ({ ...prev, linedetailcache: `Error clearing Line Detail cache: ${error.message}` }));
      setLastLineDetailClearedTime(`Error clearing cache: ${error.message}`);
    });
  }, []);

  const fetchLastLineDetailClearedTime = useCallback(() => {
    fetch('/api/line-details/get_last_colp_cache_clear_time', { method: 'GET' })
      .then(response => {
        if (!response.ok) {  throw new Error('Failed to fetch last cache clear time');  }
        return response.json(); })
      .then(data => {  setLastLineDetailClearedTime(data.last_cleared_time);  })
      .catch(error => {  console.error('Fetch error:', error);
        setLastLineDetailClearedTime(`Error fetching last cleared time: ${error.message}`);  });
  }, []);

  const handleClearCampaignsCache = useCallback(() => {
    fetch('/api/campaigns/clear_cache', { method: 'POST' })
      .then(response => {
        if (!response.ok) {
          throw new Error('Failed to clear Campaigns cache');
        }
        return response.json();
      })
      .then(data => {
        console.log(data.message);
        setMessages(prev => ({ ...prev, campaigns: data.message }));
        fetchLastCampaignsClearedTime(); 
      })
      .catch(error => {
        console.log(error.message);
        setMessages(prev => ({ ...prev, campaigns: `Error clearing Campaigns cache: ${error.message}` }));
        setLastCampaignsClearedTime(`Error clearing cache: ${error.message}`);
      });
  }, []);

  const fetchLastCampaignsClearedTime = useCallback(() => {
    fetch('/api/campaigns/get_last_campaigns_cache_clear_time', { method: 'GET' })
      .then(response => {
        if (!response.ok) {  throw new Error('Failed to fetch last cache clear time');  }
        return response.json(); })
      .then(data => {  setLastCampaignsClearedTime(data.last_cleared_time);  })
      .catch(error => {  console.error('Fetch error:', error);
        setLastCampaignsClearedTime(`Error fetching last cleared time: ${error.message}`);  });
  }, []);
  

  const handleClearAdsKeywordCache = useCallback(() => {
    fetch('/api/ads/ads_keywords_data', { method: 'POST' })
      .then(response => {
        if (!response.ok) {
          throw new Error('Failed to clear Ads Keyword cache');
        }
        return response.json();
      })
      .then(data => {
        console.log(data.message);
        setMessages(prev => ({ ...prev, adskeywords: data.message }));
        fetchLastAdsKeywordClearedTime(); 
      })
      .catch(error => {
        console.log(error.message);
        setMessages(prev => ({ ...prev, adskeywords: `Error clearing Ads Keywords cache: ${error.message}` }));
        setLastAdsKeywordClearedTime(`Error clearing cache: ${error.message}`);
      });
  }, []);
  const fetchLastAdsKeywordClearedTime = useCallback(() => {
    fetch('/api/ads/get_last_adskeywords_cache_clear_time', { method: 'GET' })
      .then(response => {
        if (!response.ok) {  throw new Error('Failed to fetch last cache clear time');  }
        return response.json(); })
      .then(data => {  setLastAdsKeywordClearedTime(data.last_cleared_time);  })
      .catch(error => {  console.error('Fetch error:', error);
        setLastAdsKeywordClearedTime(`Error fetching last cleared time: ${error.message}`);  });
  }, []);

  const [refreshMessages, setRefreshMessages] = useState({});
  const handleRefreshClusteredSpapiTable = useCallback(() => {
    setRefreshMessages(prev => ({ ...prev, spapiTable: 'Refreshing Clustered View...' }));
    
    fetch('/api/line/refresh_clustered_spapi_table', { method: 'POST' })
      .then(response => {
        if (!response.ok) {
          throw new Error('Failed to refresh Clustered SP-API table');
        }
        return response.json();
      })
      .then(data => {
        console.log(data.message);
        setRefreshMessages(prev => ({ ...prev, spapiTable: data.message }));
      })
      .catch(error => {
        console.error('Error:', error);
        setRefreshMessages(prev => ({ ...prev, spapiTable: `Error refreshing Clustered SP-API table: ${error.message}` }));
      });
  }, []);

  const handleRefreshClusteredHQCOSP = useCallback(() => {
    setRefreshMessages(prev => ({ ...prev, hqCosp: 'Refreshing Clustered HQ COSP View....' }));
    
    fetch('/api/line/refresh_calc_cosp_hq_clustered', { method: 'POST' })
      .then(response => {
        if (!response.ok) {
          throw new Error('Failed to refresh Clustered HQ COSP table');
        }
        return response.json();
      })
      .then(data => {
        console.log(data.message);
        setRefreshMessages(prev => ({ ...prev, hqCosp: data.message }));
      })
      .catch(error => {
        console.error('Error:', error);
        setRefreshMessages(prev => ({ ...prev, hqCosp: `Error refreshing Clustered HQ COSP table: ${error.message}` }));
      });
  }, []);
  
  useEffect(() => {
    fetchLastClearedTime(); fetchLastCospClearedTime();  fetchLastLineDetailClearedTime(); fetchLastCampaignsClearedTime();
    fetchLastAdsKeywordClearedTime();
  }, [fetchLastClearedTime, fetchLastCospClearedTime, fetchLastLineDetailClearedTime, fetchLastCampaignsClearedTime, fetchLastAdsKeywordClearedTime]);

  return (
    <Box mt={2} sx={{ padding: 2 }}>
      <Typography variant="h4" gutterBottom fontWeight="bold" color="primary">
        Cache Clearing Panel
      </Typography>
      <Paper elevation={3} sx={{ padding: 3, marginBottom: 3 }}>
        <Typography variant="body1" paragraph>
          Use these buttons once or twice a day to clear specific cache when its data appears to be outdated.
          After clearing the cache, refresh the page to retrieve the most recent data.
        </Typography>
        <Typography variant="h6" color="error" fontWeight="bold" paragraph>
          Don't use frequently as it affects all users.
        </Typography>
      </Paper>

      <Typography variant="h5" gutterBottom fontWeight="bold">
        Clear Cache
      </Typography>
      <Box display="flex" flexDirection="column" gap={2} mb={4}>
        <CacheButton
          label="Clear Sku Details Cache"
          onClick={handleClearCOSPCache}
          lastCleared={lastCospClearedTime}
        />
        <CacheButton
          label="Clear Line Details Cache"
          onClick={handleClearLineDetailCache}
          lastCleared={lastLineDetailClearedTime}
        />
        <CacheButton
          label="Clear Line Daily/Monthly"
          onClick={handleClearLineCache}
          lastCleared={lastClearedTime}
        />
        <CacheButton
          label="Clear Campaigns Cache"
          onClick={handleClearCampaignsCache}
          lastCleared={lastCampaignsClearedTime}
        />
      <CacheButton
          label="Clear Ads Keyword Cache"
          onClick={handleClearAdsKeywordCache}
          lastCleared={lastAdsKeywordClearedTime}
        />
      </Box>

      <Typography variant="h5" gutterBottom fontWeight="bold">
        Refresh Clustered Tables
      </Typography>
      <Box display="flex" flexDirection="column" gap={2}>
        <RefreshButton
          label="Clustered SP-Api Table"
          onClick={handleRefreshClusteredSpapiTable}
        />
        <RefreshButton
          label="Clustered HQ COSP Table"
          onClick={handleRefreshClusteredHQCOSP}
        />
      </Box>

      {Object.entries(messages).map(([key, message]) => (
        message && (
          <Snackbar
            key={key}
            open={!!message}
            autoHideDuration={6000}
            onClose={() => setMessages(prev => ({ ...prev, [key]: '' }))}
            message={message}
            sx={{  ml :'300px', mb : '100px'}}
          />
        )
      ))}

      {/* Refresh Clustered Messages */}
      {Object.entries(refreshMessages).map(([key, message]) => (
       message && (
        <Snackbar
          key={key}
          open={!!message}
          autoHideDuration={60000}
          onClose={() => setRefreshMessages(prev => ({ ...prev, [key]: '' }))}
          message={message}
          sx={{ ml :'300px', mb: '30px' }}
         />
          )
      ))}
    </Box>
  );
};

const CacheButton = ({ label, onClick, lastCleared }) => (
  <Box display="flex" alignItems="center" gap={2}>
    <Button
      variant="contained" color="secondary" onClick={onClick} startIcon={<DeleteIcon />}
      sx={{ width: '280px', fontWeight: 'bold' }}
    >
      {label}
    </Button>
    <Typography variant="body2" fontStyle="italic">
      Last cleared: {lastCleared || 'N/A'}
    </Typography>
  </Box>
);

const RefreshButton = ({ label, onClick }) => (
  <Button
    variant="contained"
    color="primary"
    onClick={onClick}
    startIcon={<RefreshIcon />}
    sx={{ width: '280px', fontWeight: 'bold' }}
  >
    {label}
  </Button>
);

export default CacheClearingPanel;
